/** @jsx jsx */
import { useThemeUI, jsx } from "theme-ui"

type Props = {
  className?: string
  size: number
  color: string
  top: string | string[]
  left?: string | string[]
  right?: string | string[]
}

const CircleGrid = ({ className, color, size, top, left, right }: Props) => {
  const { theme } = useThemeUI()

  const fillColor = theme.colors[color][2]

  return (
    <svg
      className={className}
      viewBox="0 0 173 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        position: `absolute`,
        height: size,
        width: (173 / 127) * size,
        top,
        left,
        right,
        zIndex: -1,
      }}
    >
      <path
        d="M5.16418 10.3673C8.01627 10.3673 10.3284 8.04653 10.3284 5.18367C10.3284 2.32081 8.01627 0 5.16418 0C2.31208 0 0 2.32081 0 5.18367C0 8.04653 2.31208 10.3673 5.16418 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M28.403 10.3673C31.2551 10.3673 33.5672 8.04653 33.5672 5.18367C33.5672 2.32081 31.2551 0 28.403 0C25.5509 0 23.2388 2.32081 23.2388 5.18367C23.2388 8.04653 25.5509 10.3673 28.403 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 10.3673C54.4939 10.3673 56.806 8.04654 56.806 5.18367C56.806 2.32081 54.4939 0 51.6418 0C48.7897 0 46.4776 2.32081 46.4776 5.18367C46.4776 8.04654 48.7897 10.3673 51.6418 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 10.3673C77.7327 10.3673 80.0448 8.04654 80.0448 5.18367C80.0448 2.32081 77.7327 0 74.8806 0C72.0285 0 69.7164 2.32081 69.7164 5.18367C69.7164 8.04654 72.0285 10.3673 74.8806 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 10.3673C100.972 10.3673 103.284 8.04653 103.284 5.18367C103.284 2.32081 100.972 0 98.1194 0C95.2673 0 92.9552 2.32081 92.9552 5.18367C92.9552 8.04653 95.2673 10.3673 98.1194 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M121.358 10.3673C124.21 10.3673 126.522 8.04653 126.522 5.18367C126.522 2.32081 124.21 0 121.358 0C118.506 0 116.194 2.32081 116.194 5.18367C116.194 8.04653 118.506 10.3673 121.358 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M144.597 10.3673C147.449 10.3673 149.761 8.04654 149.761 5.18367C149.761 2.32081 147.449 0 144.597 0C141.745 0 139.433 2.32081 139.433 5.18367C139.433 8.04654 141.745 10.3673 144.597 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M167.836 10.3673C170.688 10.3673 173 8.04654 173 5.18367C173 2.32081 170.688 0 167.836 0C164.984 0 162.672 2.32081 162.672 5.18367C162.672 8.04654 164.984 10.3673 167.836 10.3673Z"
        fill={fillColor}
      />
      <path
        d="M5.16418 33.6939C8.01627 33.6939 10.3284 31.3731 10.3284 28.5102C10.3284 25.6473 8.01627 23.3265 5.16418 23.3265C2.31208 23.3265 0 25.6473 0 28.5102C0 31.3731 2.31208 33.6939 5.16418 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M28.403 33.6939C31.2551 33.6939 33.5672 31.3731 33.5672 28.5102C33.5672 25.6473 31.2551 23.3265 28.403 23.3265C25.5509 23.3265 23.2388 25.6473 23.2388 28.5102C23.2388 31.3731 25.5509 33.6939 28.403 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 33.6939C54.4939 33.6939 56.806 31.3731 56.806 28.5102C56.806 25.6473 54.4939 23.3265 51.6418 23.3265C48.7897 23.3265 46.4776 25.6473 46.4776 28.5102C46.4776 31.3731 48.7897 33.6939 51.6418 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 33.6939C77.7327 33.6939 80.0448 31.3731 80.0448 28.5102C80.0448 25.6473 77.7327 23.3265 74.8806 23.3265C72.0285 23.3265 69.7164 25.6473 69.7164 28.5102C69.7164 31.3731 72.0285 33.6939 74.8806 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 33.6939C100.972 33.6939 103.284 31.3731 103.284 28.5102C103.284 25.6473 100.972 23.3265 98.1194 23.3265C95.2673 23.3265 92.9552 25.6473 92.9552 28.5102C92.9552 31.3731 95.2673 33.6939 98.1194 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M121.358 33.6939C124.21 33.6939 126.522 31.3731 126.522 28.5102C126.522 25.6473 124.21 23.3265 121.358 23.3265C118.506 23.3265 116.194 25.6473 116.194 28.5102C116.194 31.3731 118.506 33.6939 121.358 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M144.597 33.6939C147.449 33.6939 149.761 31.3731 149.761 28.5102C149.761 25.6473 147.449 23.3265 144.597 23.3265C141.745 23.3265 139.433 25.6473 139.433 28.5102C139.433 31.3731 141.745 33.6939 144.597 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M167.836 33.6939C170.688 33.6939 173 31.3731 173 28.5102C173 25.6473 170.688 23.3265 167.836 23.3265C164.984 23.3265 162.672 25.6473 162.672 28.5102C162.672 31.3731 164.984 33.6939 167.836 33.6939Z"
        fill={fillColor}
      />
      <path
        d="M5.16418 57.0204C8.01627 57.0204 10.3284 54.6996 10.3284 51.8367C10.3284 48.9739 8.01627 46.6531 5.16418 46.6531C2.31208 46.6531 0 48.9739 0 51.8367C0 54.6996 2.31208 57.0204 5.16418 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M28.403 57.0204C31.2551 57.0204 33.5672 54.6996 33.5672 51.8367C33.5672 48.9739 31.2551 46.6531 28.403 46.6531C25.5509 46.6531 23.2388 48.9739 23.2388 51.8367C23.2388 54.6996 25.5509 57.0204 28.403 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 57.0204C54.4939 57.0204 56.806 54.6996 56.806 51.8367C56.806 48.9739 54.4939 46.6531 51.6418 46.6531C48.7897 46.6531 46.4776 48.9739 46.4776 51.8367C46.4776 54.6996 48.7897 57.0204 51.6418 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 57.0204C77.7327 57.0204 80.0448 54.6996 80.0448 51.8367C80.0448 48.9739 77.7327 46.6531 74.8806 46.6531C72.0285 46.6531 69.7164 48.9739 69.7164 51.8367C69.7164 54.6996 72.0285 57.0204 74.8806 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 57.0204C100.972 57.0204 103.284 54.6996 103.284 51.8367C103.284 48.9739 100.972 46.6531 98.1194 46.6531C95.2673 46.6531 92.9552 48.9739 92.9552 51.8367C92.9552 54.6996 95.2673 57.0204 98.1194 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M121.358 57.0204C124.21 57.0204 126.522 54.6996 126.522 51.8367C126.522 48.9739 124.21 46.6531 121.358 46.6531C118.506 46.6531 116.194 48.9739 116.194 51.8367C116.194 54.6996 118.506 57.0204 121.358 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M144.597 57.0204C147.449 57.0204 149.761 54.6996 149.761 51.8367C149.761 48.9739 147.449 46.6531 144.597 46.6531C141.745 46.6531 139.433 48.9739 139.433 51.8367C139.433 54.6996 141.745 57.0204 144.597 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M167.836 57.0204C170.688 57.0204 173 54.6996 173 51.8367C173 48.9739 170.688 46.6531 167.836 46.6531C164.984 46.6531 162.672 48.9739 162.672 51.8367C162.672 54.6996 164.984 57.0204 167.836 57.0204Z"
        fill={fillColor}
      />
      <path
        d="M5.16418 80.3469C8.01627 80.3469 10.3284 78.0261 10.3284 75.1633C10.3284 72.3004 8.01627 69.9796 5.16418 69.9796C2.31208 69.9796 0 72.3004 0 75.1633C0 78.0261 2.31208 80.3469 5.16418 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M28.403 80.3469C31.2551 80.3469 33.5672 78.0261 33.5672 75.1633C33.5672 72.3004 31.2551 69.9796 28.403 69.9796C25.5509 69.9796 23.2388 72.3004 23.2388 75.1633C23.2388 78.0261 25.5509 80.3469 28.403 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 80.3469C54.4939 80.3469 56.806 78.0261 56.806 75.1633C56.806 72.3004 54.4939 69.9796 51.6418 69.9796C48.7897 69.9796 46.4776 72.3004 46.4776 75.1633C46.4776 78.0261 48.7897 80.3469 51.6418 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 80.3469C77.7327 80.3469 80.0448 78.0261 80.0448 75.1633C80.0448 72.3004 77.7327 69.9796 74.8806 69.9796C72.0285 69.9796 69.7164 72.3004 69.7164 75.1633C69.7164 78.0261 72.0285 80.3469 74.8806 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 80.3469C100.972 80.3469 103.284 78.0261 103.284 75.1633C103.284 72.3004 100.972 69.9796 98.1194 69.9796C95.2673 69.9796 92.9552 72.3004 92.9552 75.1633C92.9552 78.0261 95.2673 80.3469 98.1194 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M121.358 80.3469C124.21 80.3469 126.522 78.0261 126.522 75.1633C126.522 72.3004 124.21 69.9796 121.358 69.9796C118.506 69.9796 116.194 72.3004 116.194 75.1633C116.194 78.0261 118.506 80.3469 121.358 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M144.597 80.3469C147.449 80.3469 149.761 78.0261 149.761 75.1633C149.761 72.3004 147.449 69.9796 144.597 69.9796C141.745 69.9796 139.433 72.3004 139.433 75.1633C139.433 78.0261 141.745 80.3469 144.597 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M167.836 80.3469C170.688 80.3469 173 78.0261 173 75.1633C173 72.3004 170.688 69.9796 167.836 69.9796C164.984 69.9796 162.672 72.3004 162.672 75.1633C162.672 78.0261 164.984 80.3469 167.836 80.3469Z"
        fill={fillColor}
      />
      <path
        d="M5.16418 103.673C8.01627 103.673 10.3284 101.353 10.3284 98.4898C10.3284 95.6269 8.01627 93.3061 5.16418 93.3061C2.31208 93.3061 0 95.6269 0 98.4898C0 101.353 2.31208 103.673 5.16418 103.673Z"
        fill={fillColor}
      />
      <path
        d="M28.403 103.673C31.2551 103.673 33.5672 101.353 33.5672 98.4898C33.5672 95.6269 31.2551 93.3061 28.403 93.3061C25.5509 93.3061 23.2388 95.6269 23.2388 98.4898C23.2388 101.353 25.5509 103.673 28.403 103.673Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 103.673C54.4939 103.673 56.806 101.353 56.806 98.4898C56.806 95.6269 54.4939 93.3061 51.6418 93.3061C48.7897 93.3061 46.4776 95.6269 46.4776 98.4898C46.4776 101.353 48.7897 103.673 51.6418 103.673Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 103.673C77.7327 103.673 80.0448 101.353 80.0448 98.4898C80.0448 95.6269 77.7327 93.3061 74.8806 93.3061C72.0285 93.3061 69.7164 95.6269 69.7164 98.4898C69.7164 101.353 72.0285 103.673 74.8806 103.673Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 103.673C100.972 103.673 103.284 101.353 103.284 98.4898C103.284 95.6269 100.972 93.3061 98.1194 93.3061C95.2673 93.3061 92.9552 95.6269 92.9552 98.4898C92.9552 101.353 95.2673 103.673 98.1194 103.673Z"
        fill={fillColor}
      />
      <path
        d="M121.358 103.673C124.21 103.673 126.522 101.353 126.522 98.4898C126.522 95.6269 124.21 93.3061 121.358 93.3061C118.506 93.3061 116.194 95.6269 116.194 98.4898C116.194 101.353 118.506 103.673 121.358 103.673Z"
        fill={fillColor}
      />
      <path
        d="M144.597 103.673C147.449 103.673 149.761 101.353 149.761 98.4898C149.761 95.6269 147.449 93.3061 144.597 93.3061C141.745 93.3061 139.433 95.6269 139.433 98.4898C139.433 101.353 141.745 103.673 144.597 103.673Z"
        fill={fillColor}
      />
      <path
        d="M167.836 103.673C170.688 103.673 173 101.353 173 98.4898C173 95.6269 170.688 93.3061 167.836 93.3061C164.984 93.3061 162.672 95.6269 162.672 98.4898C162.672 101.353 164.984 103.673 167.836 103.673Z"
        fill={fillColor}
      />
      <path
        d="M5.16418 127C8.01627 127 10.3284 124.679 10.3284 121.816C10.3284 118.953 8.01627 116.633 5.16418 116.633C2.31208 116.633 0 118.953 0 121.816C0 124.679 2.31208 127 5.16418 127Z"
        fill={fillColor}
      />
      <path
        d="M28.403 127C31.2551 127 33.5672 124.679 33.5672 121.816C33.5672 118.953 31.2551 116.633 28.403 116.633C25.5509 116.633 23.2388 118.953 23.2388 121.816C23.2388 124.679 25.5509 127 28.403 127Z"
        fill={fillColor}
      />
      <path
        d="M51.6418 127C54.4939 127 56.806 124.679 56.806 121.816C56.806 118.953 54.4939 116.633 51.6418 116.633C48.7897 116.633 46.4776 118.953 46.4776 121.816C46.4776 124.679 48.7897 127 51.6418 127Z"
        fill={fillColor}
      />
      <path
        d="M74.8806 127C77.7327 127 80.0448 124.679 80.0448 121.816C80.0448 118.953 77.7327 116.633 74.8806 116.633C72.0285 116.633 69.7164 118.953 69.7164 121.816C69.7164 124.679 72.0285 127 74.8806 127Z"
        fill={fillColor}
      />
      <path
        d="M98.1194 127C100.972 127 103.284 124.679 103.284 121.816C103.284 118.953 100.972 116.633 98.1194 116.633C95.2673 116.633 92.9552 118.953 92.9552 121.816C92.9552 124.679 95.2673 127 98.1194 127Z"
        fill={fillColor}
      />
      <path
        d="M121.358 127C124.21 127 126.522 124.679 126.522 121.816C126.522 118.953 124.21 116.633 121.358 116.633C118.506 116.633 116.194 118.953 116.194 121.816C116.194 124.679 118.506 127 121.358 127Z"
        fill={fillColor}
      />
      <path
        d="M144.597 127C147.449 127 149.761 124.679 149.761 121.816C149.761 118.953 147.449 116.633 144.597 116.633C141.745 116.633 139.433 118.953 139.433 121.816C139.433 124.679 141.745 127 144.597 127Z"
        fill={fillColor}
      />
      <path
        d="M167.836 127C170.688 127 173 124.679 173 121.816C173 118.953 170.688 116.633 167.836 116.633C164.984 116.633 162.672 118.953 162.672 121.816C162.672 124.679 164.984 127 167.836 127Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default CircleGrid
